import { getStorage } from '../firebase';
import { cuuid } from '../utils/cuuid';

export const uploadFile = async (file, path) => {
  if (!file || !path) {
    throw Error('Missing file or path');
  }

  const { storage, ref, uploadBytes, getDownloadURL } = await getStorage();

  const storageRef = ref(storage, `${path}/${cuuid()}`);

  // 'file' comes from the Blob or File API
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef)

  return url;
};


export const deleteFileFromURL = async (fileURL: string) => {
  const { storage, ref, deleteObject } = await getStorage();
  return deleteObject(ref(storage, fileURL));
}
