import { useState, useEffect } from 'react';
import { getAuth, getStore } from "../firebase";
import { API_URL } from "../constants";
import { getDateTime } from "../utils/date";
import { deleteFileFromURL } from "./storage";

const EVENTS = "events";

export type EventDoc = {
  ageRangeFrom:number
  ageRangeTo: number
  archived: boolean
  banner: string
  createdAt: string
  dateTime: string
  description: string
  designMyNightId: string
  discountCodes: {
    code: string
    percent: number
  }[]
  featured: boolean
  howItWorks: {
    col1: {
      description: string
      imgURL: string
    }
    col2: {
      description: string
      imgURL: string
    }
    col3: {
      description: string
      imgURL: string
    }
    finalNotes: string
  }
  id: string
  imgMobileUrl: string
  imgURL: string
  isSameGender?: boolean
  lowercaseTitle: string
  menAvailableTickets: number
  notifiedUsers: boolean
  participants: any
  showBanner: boolean
  ticketPrice: number
  ticketPriceWithoutDiscount?: number
  title: string
  venue: string
  venueAddress?: string
  venueDescription?: string
  votingPeriodEnded?: boolean
  womenAvailableTickets: number
}

export const useFetchEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadEvents = async () => {
      setLoading(true);
      try {
        const events = await getAllEvents();
        if (events.length) {
          setEvents(events);
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
    loadEvents();
  }, []);

  return [ events, loading ];

}

export const addEvent = async (event) => {
  const { db, addDoc, collection } = await getStore();
  return addDoc(collection(db, EVENTS), {
    createdAt: new Date(),
    ...event,
  });
}

export const getEvent = async (id) => {
  if (!id) return;
  const { db, doc, getDoc } = await getStore();
  const docRef = doc(db, EVENTS, id);
  const eventData = (await getDoc(docRef)).data();
  if (!eventData.dateTime) {
    throw new Error(`dateTime not found in event ${eventData.id}`)
  }
  return eventData
    ? {
        ...eventData,
        dateTime: getDateTime(eventData.dateTime),
        createdAt: getDateTime(eventData.createdAt),
      }
    : eventData;
};

export const getAllEvents = async () => {
  try {
    const { db, query, collection, orderBy, getDocs } = await getStore();
    const q = query(collection(db, EVENTS), orderBy("dateTime", "asc"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
      const eventData = doc.data();
      return {
        ...eventData,
        id: doc.id,
        dateTime: getDateTime(eventData.dateTime),
        createdAt: getDateTime(eventData.createdAt),
      };
    });
  } catch (e) {
    return ["something"];
  }
};

export const getDynamicFilters = async () => {
  try {
    const { db, query, collection, where, getDocs } = await getStore();
    const q = query(
      collection(db, EVENTS),
      where("archived", "in", [false, null])
    );
    const querySnapshot = await getDocs(q);

    const [ageRangesSet, locationsSet] = querySnapshot.docs.reduce((acc, doc) => {
      const eventData = doc.data();
      acc[0].add(`${eventData.ageRangeFrom}${eventData.ageRangeTo ? ' - ' + eventData.ageRangeTo : '+'}`);
      acc[1].add(eventData.venue);
      return acc;
    }, [new Set<string>(), new Set<string>()]);

    return  {
      locations: Array.from(locationsSet).sort(),
      ages: Array.from(ageRangesSet).sort()
    }
  } catch (e) {
    return {
      locations: [],
      ages: [],
    }
  }
}

export const getLocations = async () => {
  const { locations } = await getDynamicFilters();
  return locations;
};

export const getAges = async () => {
  const { ages } = await getDynamicFilters();
  return ages;
};

type EventsQueries = {
  ageFrom?: number
  ageTo?: number
  type?: string
  date?: string
  location?: string
  showGayEvents?: boolean
  showChristianEvents?: boolean
  showSpeedEvents?: boolean
}

export const getEventsCardData = async (limitAmount, queries:EventsQueries = {}) => {
  const { db, query, collection, where, orderBy, getDocs, limit } = await getStore();
  let q = query(
    collection(db, EVENTS),
    where("archived", "in", [false, null]),
    orderBy("dateTime", "asc")
  );

  if (Object.keys(queries).length == 0 && limitAmount > 0) {
    q = query(q, limit(limitAmount));
  }

  const querySnapshot = await getDocs(q);
  let results = querySnapshot.docs.map((doc) => {
    const eventData = doc.data();
    return {
      imgURL: eventData.imgURL,
      title: eventData.title,
      venue: eventData.venue,
      ageRangeFrom: eventData.ageRangeFrom,
      ageRangeTo: eventData.ageRangeTo,
      menAvailableTickets: eventData.menAvailableTickets,
      womenAvailableTickets: eventData.womenAvailableTickets,
      ticketPrice: eventData.ticketPrice,
      ticketPriceWithoutDiscount: eventData.ticketPriceWithoutDiscount ?? null,
      featured: eventData.featured,
      isSameGender: eventData?.isSameGender ?? false,
      id: doc.id,
      dateTime: getDateTime(eventData.dateTime),
      createdAt: getDateTime(eventData.createdAt),
    };
  });

  if (Object.keys(queries).length > 0) {
    results = results.filter((event) => {
      const filterResults = [];
      if (queries.ageFrom) {
        filterResults.push(event.ageRangeFrom >= queries.ageFrom);
      }
      if (queries.ageTo) {
        filterResults.push(event.ageRangeTo <= queries.ageTo);
      }
      if (queries.type) {
        if (queries.type === "Gay") {
          filterResults.push(event.isSameGender);
        } else {
          filterResults.push(event.title.indexOf(queries.type) > -1);
        }
      }
      if (queries.date) {
        filterResults.push(
          new Date(event.dateTime).toLocaleDateString() ===
            new Date(queries.date).toLocaleDateString()
        );
      }
      if (queries.location) {
        filterResults.push(
          event.venue.toLowerCase().includes(queries.location.toLowerCase())
        );
      }
      if (queries.showGayEvents) {
        filterResults.push(event.isSameGender);
      }
      if (queries.showChristianEvents) {
        filterResults.push(event.title.indexOf("Christian") > -1);
      }
      if (queries.showSpeedEvents) {
        filterResults.push(event.title.indexOf("Speed") > -1);
      }
      return filterResults.every((result) => result);
    });
  }

  results.sort((a, b) => {
    return a.featured === b.featured ? 0 : a.featured ? -1 : 1;
  });

  return limitAmount ? results.slice(0, limitAmount) : results;
};

export const getActiveEvents = async () => {
  const { db, query, collection, where, orderBy, getDocs } = await getStore();
  const q = query(
    collection(db, EVENTS),
    where("archived", "in", [false, null]),
    orderBy("dateTime", "asc")
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {

    const eventData = doc.data() as EventDoc;
    if (!eventData.dateTime) {
      throw new Error(`dateTime not found in event ${eventData.id}`)
    }
    return {
      ...eventData,
      id: doc.id,
      dateTime: getDateTime(eventData.dateTime),
      createdAt: getDateTime(eventData.createdAt),
    };
  });
};

export const updateEvent = async ({ id, ...eventDetails }) => {
  const { db, doc, updateDoc } = await getStore();
  return updateDoc(doc(db, EVENTS, id), eventDetails);
}

export const deleteEvents = async (events) => {
  if (!events) return;
  const { db, doc, writeBatch } = await getStore();
  const batch = writeBatch(db);
  events.forEach((event) => {
    const { id, imgUrl } = event;
    const eventRef = doc(db, EVENTS, id);
    if (eventRef) {
      batch.delete(eventRef);
      if (imgUrl) {
        deleteFileFromURL(imgUrl);
      }
    }
  });
  return batch.commit();
};

export const archiveEvents = async (events) => {
  if (!events) return;

  return events.forEach((event) => {
    event.archived = true;
    updateEvent({ id: event.id, ...event });
  });
};

export const unarchiveEvents = (events) => {
  if (!events) return;

  events.forEach((event) => {
    event.archived = false;
    updateEvent({ id: event.id, ...event });
  });
};

export const addUsersToEvent = (users, eventId) =>
  Promise.all(
    users.map((user) =>
      fetch(`${API_URL}/inviteUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user,
          eventId,
        }),
      })
    )
  ).catch((err) => console.log(err));
