import { ArrowRightAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dynamic from "next/dynamic";
import Head from "next/head";
import Image from "next/image";
import Router, { useRouter } from "next/router";

import BestDatingSite from "../src/components/BestDatingSite";
import Reviews from "../src/components/Reviews";
import TopBanner from "../src/components/TopBanner";
// import EventSearch from "../src/components/EventSearch";
const EventSearch = dynamic(() => import("../src/components/EventSearch"), {
  loading: () => <CircularProgress />,
});

import { routes } from "../src/routes";

const HowItWorksSection = dynamic(
  () => import("../src/components/HowItWorksSection"),
  { 
    loading: () => <CircularProgress />,
  }
);

import { COLORS } from "../src/theme";

import refund from "../public/images/Group 67.png";
import bigBen from "../public/images/big-ben.svg";
import care from "../public/images/care.svg";
import creditCard from "../public/images/credit-card.svg";
import handShake from "../public/images/handshake.svg";
import homebanner from "../public/images/home_banner1_optimized.jpeg";
import homebannerMobile from "../public/images/homebanner_mobile.jpeg";
import asianNetwork from "../public/images/partners/asian_network.png";
import eveningStandard from "../public/images/partners/evening_standard.png";
import itv from "../public/images/partners/itv.png";
import lbc from "../public/images/partners/lbc.png";
import metro from "../public/images/partners/metro.png";
import safety from "../public/images/surface1.svg";
import {
  getAges,
  getDynamicFilters,
  getEventsCardData,
  getLocations,
} from "../src/services/event";
import { getTopBanner } from "../src/services/offers";

import folly from "../public/images/folly.jpg";
import mintleaf from "../public/images/mintleaf.jpg";
import riverside from "../public/images/riverside.jpg";

export default function Index({ events, locations, ages, isTopBannerEnabled }) {
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Head>
        <title>Speed Dating London | Singles Parties | True Dating</title>
        <meta
          name="description"
          content="Discover the ultimate way to meet London singles with True Dating's Speed Dating. Join our platform to enjoy fantastic London venues for speed Dating London."
        />
        <meta
          name="keywords"
          content="speed Dating, speed Dating London, dating in London, dating site in london, online speed dating, single Parties"
        />
      </Head>
      <Collapse in={isTopBannerEnabled}>
        <TopBanner />
      </Collapse>
      <div id="home-page">
        <section id="home_banner">
          {isMobile ? (
            <Image src={homebannerMobile} fill alt="Home Banner" priority />
          ) : (
            <Image src={homebanner} fill alt="Home Banner" priority />
          )}
          <div className="row">
            <div className="six-col off-six column">
              <div className="content">
                <h1>
                  Stop Swiping.
                  <br />
                  <span>
                    Start Dating.
                    <br />
                  </span>
                  <span className="subHeading">
                    Speed Dating London & Singles Events.
                    <br />
                    <span className="desktopLineBreak" />
                    Better prices, venues and hosts than anywhere else.
                  </span>
                </h1>
                <Box pt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowRightAlt />}
                    type="submit"
                    size="large"
                    onClick={() => Router.push(routes.events)}
                  >
                    Book Now
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </section>

        <Box pb={4} className="home_upcoming">
          <EventSearch
            events={events}
            title={
              <Box>
                <h2
                  style={{
                    fontSize: "32px",
                    fontWeight: 400,
                  }}
                >
                  <strong>Upcoming</strong> Events
                </h2>
              </Box>
            }
            limitCards={isMobile ? 15 : 15}
            bgColor="#fff"
            locations={locations}
            ages={ages}
          />

          {events.length > 0 && (
            <Box textAlign="center" py={4}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowRightAlt />}
                type="submit"
                size="large"
                onClick={() => Router.push(routes.events)}
              >
                See More Events
              </Button>
            </Box>
          )}
        </Box>

        <HowItWorksSection bgColor={COLORS.lightGrey} />

        <Container>
          <section id="partners">
            <Box pt={4} pb={6}>
              <h2>
                We've Been<span> Featured On:</span>
              </h2>
            </Box>
            <div className="row">
              <div>
                <Image src={asianNetwork} alt="Asian Network" />
              </div>
              <div>
                <Image src={eveningStandard} alt="Evening Standart" />
              </div>
              <div>
                <Image src={itv} alt="True Dating has Been Featured On:" />
              </div>
              <div>
                <Image src={lbc} alt="True Dating has Been Featured On:" />
              </div>
              <div>
                <Image src={metro} alt="True Dating has Been Featured On:" />
              </div>
            </div>
          </section>

          <section id="whychoose">
            <h2>
              Why Choose <span>True Dating</span>
            </h2>
            <div className="grid-cont">
              <div>
                <div className="img-cont">
                  <Image src={handShake} alt="Brilliant Hosts | True Dating" />
                </div>
                <div className="text-cont">
                  <Box py={2}>
                    <h3>Brilliant Speed Dating Hosts</h3>
                  </Box>
                  <p>
                    You will be welcomed by a host (or two) to keep
                    conversations, drinks and the good times flowing.
                  </p>
                </div>
              </div>
              <div>
                <div className="img-cont">
                  <Image
                    src={bigBen}
                    alt="Fantastic London Venues | True Dating"
                  />
                </div>
                <div className="text-cont">
                  <Box py={2}>
                    <h3>Fantastic Speed Dating London Venues</h3>
                  </Box>
                  <p>
                    You will only find us in the top venues across London –
                    lively, safe and easy to access.
                  </p>
                </div>
              </div>
              <div>
                <div className="img-cont">
                  <Image src={refund} alt="Refund Policy | True Dating" />
                </div>
                <div className="text-cont">
                  <Box py={2}>
                    <h3>Refund Policy</h3>
                  </Box>
                  <p>
                    Although all tickets are strictly non-refundable, we will
                    transfer any tickets if given at least 24 hours’ notice
                    prior to the event.
                  </p>
                </div>
              </div>
              <div>
                <div className="img-cont">
                  <Image
                    src={creditCard}
                    alt="Easy Payment Method | True Dating"
                  />
                </div>
                <div className="text-cont">
                  <Box py={2}>
                    <h3>Easy Payment Method</h3>
                  </Box>
                  <p>
                    Purchasing a ticket is smooth and secure. Store your payment
                    card for quick check-out.
                  </p>
                </div>
              </div>
              <div>
                <div className="img-cont">
                  <Image
                    src={care}
                    alt="Excellent Customer Care | True Dating"
                  />
                </div>
                <div className="text-cont">
                  <Box py={2}>
                    <h3>Excellent Customer Care</h3>
                  </Box>
                  <p>
                    Our team is always on-hand to answer any questions you have
                    and work hard to ensure you have the best experience with
                    us.
                  </p>
                </div>
              </div>
              <div>
                <div className="img-cont">
                  <Image src={safety} alt="Safety Guaranteed | True Dating" />
                </div>
                <div className="text-cont">
                  <Box py={2}>
                    <h3>100% Safety Guaranteed</h3>
                  </Box>
                  <p>
                    We operate a zero tolerance policy for any sort of unwanted
                    behaviour at all of our events – keep it classy!
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section id="fabulous">
            <Box pt={4} pb={2}>
              <h3>
                Fabulous London Venues for Speed Dating and Singles Events{" "}
              </h3>
            </Box>
            <Box>
              <p>
                Whether you attend our speed dating events or singles parties,
                we’ve made sure to hire out some of the best venues in London,
                perfect for our dating events. All located centrally across
                London, our venues all have three important qualities. They’re
                easily accessible for transport links. They offer reasonably
                priced drinks. And they have areas exclusively reserved just for
                our events. True Dating’s speed dating events and singles
                parties are the most popular in London for a reason and it all
                starts with creating the right atmosphere. Whichever venue you
                choose, you won’t be disappointed. Below are some of our
                favourites:
              </p>
              <br />
              <p>
                <strong>The Folly</strong> - Close to Cannon Street and moments
                from Monument. From their garden-inspired lounge to private
                dining rooms, The Folly is perfect for all-day drinking, dining
                and unwinding.
              </p>
              <br />
              <p>
                <strong>The Marylebone</strong> - Situated in the heart of
                Marylebone High St, The Marylebone is a modern bar specialising
                in cocktails made with the unique twist of in-house infusions.
              </p>
              <br />
              <p>
                <strong>The Anthologist</strong> - A gorgeous bar & restaurant with a cosy 
                outside seating area. From breakfast meetings to leisurely lunches 
                & after work drinks, this is the perfect spot just a short walk 
                from Bank station.
              </p>
              <br />
              <p>
                <strong>Mint Leaf Lounge</strong> - A short walk from Bank
                station, Mint Leaf Lounge is a striking and sophisticated
                restaurant and cocktail bar in the heart of the city.
              </p>
              <br />
              <p>
                <strong>The Riverside</strong> - Fantastic venue, right on the
                banks of the River Thames. With panoramic views of the London
                Eye to Battersea Power Station The Riverside is the perfect spot
                to sit back and relax, away from the hustle and bustle of
                Vauxhall.
              </p>
              <br />
              <p>
                <strong>The Roxy</strong> - The Roxy has been partying hard
                since 2003. There is something for everyone at this amazing
                venue. You can come for a chilled evening cocktail or a raging
                party late until the early hours.
              </p>
              <br />
              <p>
                <strong>The Otherist</strong> - Close to Liverpool Street, this
                is the perfect location to refuel, recharge & relax. With fantastic 
                cocktails and wines, it's the perfect Friday night spot in town. 
              </p>
            </Box>
            <div className="row">
              <div className="faboulousImageContainer">
                <Image
                  src={mintleaf}
                  style={{ objectFit: "cover" }}
                  fill
                  alt=""
                />
              </div>
              <div className="faboulousImageContainer">
                <Image src={folly} style={{ objectFit: "cover" }} fill alt="" />
              </div>
              <div className="faboulousImageContainer">
                <Image
                  src={riverside}
                  style={{ objectFit: "cover" }}
                  fill
                  alt=""
                />
              </div>
            </div>
          </section>
        </Container>

        <BestDatingSite bgColor={COLORS.lightGrey} />
        <Reviews bgColor={COLORS.lightGrey} />
      </div>
      <script
        type="application/ld+json"
        key="jsonLdOrganization"
        dangerouslySetInnerHTML={{
          __html: `{
          "@context": "https://schema.org/",
          "@type": "Organization",
          "name": "True Dating:",
          "url": "https://truedating.co.uk/",
          "logo": "https://truedating.co.uk/logo_pink.svg",
          "email": "contact@truedating.co.uk",
          "description": "We are the premier speed dating and singles events service in London, bringing like-minded singles together in a fun and electric environment.",
          "address": "Stateland Court, 3 Cranbrook Lane, London, N11 1PH",
          "telephone": "07543662855",
          "sameAs": [
            "https://twitter.com/truedating1",
            "https://www.instagram.com/true.dating/",
            "https://www.facebook.com/londondateevents"
          ]
        }
        `,
        }}
      />
      <script
        type="application/ld+json"
        key="jsonLdLocalBusiness"
        dangerouslySetInnerHTML={{
          __html: `{
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "True Dating",
          "image": "https://truedating.co.uk/logo_pink.svg",
          "@id": "",
          "url": "https://truedating.co.uk/",
          "telephone": "07543662855",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Stateland Court, 3 Cranbrook Lane",
            "addressLocality": "London",
            "postalCode": "N11 1PH",
            "addressCountry": "GB"
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday"
            ],
            "opens": "09:00",
            "closes": "19:00"
          } 
        }`,
        }}
      />
    </>
  );
}


export const getServerSideProps = async ({ query }) => {
  let events = await getEventsCardData(15, query);
  const { locations, ages } = await getDynamicFilters();

  const topBanner = await getTopBanner();

  events = events.map((event) => ({
    ...event,
    dateTime: event.dateTime.toString(),
    createdAt: event.createdAt.toString(),
  }));

  return {
    props: {
      events,
      ages,
      locations,
      isTopBannerEnabled: topBanner.enabled,
    }
  };
};


// export const getStaticProps = async ({ query }) => {
//   let events = await getEventsCardData(15, query);
//   const { locations, ages } = await getDynamicFilters();

//   const topBanner = await getTopBanner();

//   events = events.map((event) => ({
//     ...event,
//     dateTime: event.dateTime.toString(),
//     createdAt: event.createdAt.toString(),
//   }));

//   return {
//     props: {
//       events,
//       ages,
//       locations,
//       isTopBannerEnabled: topBanner.enabled,
//     },
//     revalidate: 10,
//   };
// };
